import React from "react";
import "./home.css";
import Services from "../services/services";
import Portofolio from "../galery/portofolio";
import { Link } from "react-router-dom";
import Testimonials from "../testimonials/testimonials";
import CompanyValues from "../values/companyValue";
import Partners from "../values/parteners";
import { PrimaryButton } from "../shared/Button";

function Home(props) {
  return (
    <div>
      <div className="height-100">
        <div className="bg-image  height-100"></div>
        <div className="container p-0">
          <div className="d-flex align-items-center justify-content-center">
            <div className="text-box">
              <h1 className="home-text">{props.dataHome["title"]}</h1>
              <p className="home-paragraph">
                {props.dataHome["messageHome"]}
              </p>
              <Link to="/contact" className="text-decoration-none">
                <PrimaryButton text={props.dataHome["btn"]} className="btn-margin  d-flex align-self-end"/>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <About data={props.dataAbout} /> */}
      <Services data={props.dataServices} />
      <CompanyValues data={props.companyValues} />
      <Portofolio data={props.dataHome} />
      <Testimonials data={props.dataReviews} />
      <Partners data={props.companyValues} />
    </div>
  );
}

export default Home;
