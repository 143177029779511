import React, { Component } from "react";

class LanguageSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      setLanguage: props.setLanguage,
      language: "",
    };
  }
  componentDidMount() {
    var language = localStorage.getItem("language");
    if (language === null || language === "null" || language === ""){
        language = "EN";
    }

    this.setState({ language });
    this.props.setLanguage(language);
  }

  changeLanguage = (e) => {
    var value = e.currentTarget.value;
    this.setState({
      language: value,
    });

    this.props.setLanguage(value);
  };

  render() {
    return (
      <div>
        <select
          className="mb-2 mr-1 p-1 text-light bg-dark"
          style={{ position: "fixed", right: "0", bottom: "0" }}
          value={this.state.language}
          onChange={(e) => this.changeLanguage(e)}
        >
          <option value="EN">English</option>
          <option value="FR">French</option>
          <option value="RU">Russian</option>
        </select>
      </div>
    );
  }
}
export default LanguageSelect;
