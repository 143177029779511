import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CompanyValues(props) {
    return (<div  style={{ backgroundColor: "#FAFAFA" }}>
      <div className="container pb-5 mb-2">
      <h3 className="p-5">{props.data.title}</h3>
            <div className="row">
                <div className="col p-3">
                <FontAwesomeIcon
                  icon={["fas", "home"]}
                  size="2x"
                  className="mb-3"
                  color="#454545"
                /> 
                  <h5> 
                   {props.data.travail}
                  </h5>
                </div>
                <div className="col p-3">
                <FontAwesomeIcon
                  icon={["fas", "clock"]}
                  size="2x"
                  className="mb-3"
                  color="#454545"
                /> 
                <h5>{props.data.rapide}</h5>
                </div>
                <div className="col p-3">
                <FontAwesomeIcon
                  icon={["fas", "hand-holding"]}
                  size="2x"
                  className="mb-3"
                  color="#454545"
                /> 
                    <h5>{props.data.conseil}</h5>
                </div>
                <div className="col p-3">
                <FontAwesomeIcon
                  icon={["fas", "file-invoice-dollar"]}
                  size="2x"
                  className="mb-3"
                  color="#454545"
                /> 
                <h5> {props.data.tarif}</h5>
                </div>
            </div>
      </div>
            
        </div>
    );
};

export default CompanyValues;