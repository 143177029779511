import React from "react";
import { Link } from "react-router-dom";
import img from "./images/img1.png";
import img1 from "./images/img2.png";
import img3 from "./images/img3.png";
import img4 from "./images/img4.png";
import "./services.css";

function Services(props) {
  return (
    <div>
      {/* <h3 className="pt-3 mt-5">{props.data.ourServices}</h3> */}
      <div className="container p-3 mt-5 mb-5">
        <div className="row mt-5 mb-5"> 
          <div className="d-none d-md-block col-sm-12 col-md align-self-left">
           <img  src={img} className="image" alt="Wall"/>
           <img  src={img1} className="image-top" alt="Bathroom"/>
          </div>
          <div className="col-sm-12 col-md">
              <div className="text-justify pb-5">
                <h2 className="pb-1"> <b>1. {props.data.electricity}</b> </h2>
                {props.data.electricityText}
              </div>
              <div className="text-justify  pb-5 pt-3">
                <h2 className="pb-1"> <b>2. {props.data.plumber} </b></h2>
                {props.data.plumberText}
              </div>
              <div className="text-justify pb-5 pt-3">
                <h2 className="pb-1"> <b>3. {props.data.carrelage}</b> </h2>
                {props.data.carrelageText}
              </div>
          </div>
          
        </div>
        <div className="row mt-5">
          <div className="col">
              <div className="text-justify pb-5 pt-5">
                <h2 className="pb-1"> <b>4. {props.data.plaquiste}</b> </h2>
                {props.data.plaquisterieText}
              </div>
              <div className="text-justify pb-5">
                <h2 className="pb-1"><b>5. {props.data.painting} </b></h2>
                {props.data.paintingText}
              </div>
              <Link to="/services" className="d-flex align-left">
                {props.data.otherServices}
              </Link>
          </div>
          <div className="col d-none d-md-block">
          <img  src={img3} className="image-top-right" alt="Wall"/>
           <img  src={img4} className="image-bottom-right" alt="Bathroom"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
