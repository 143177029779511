import './button.css';
import React from "react";

export function PrimaryButton({text, className}) {
    return (
        <button className={`primary-button btn ${className}`}> 
            {text}
        </button>
    )
}

export function SecondaryButton({text, className}) {
    return (
        <button className={`secondary-button btn ${className}`}> 
            {text}
        </button>
    )
}