import dataEN from "../../locales/en/translation.json";
import dataFR from "../../locales/fr/translation.json";
import dataRU from "../../locales/ru/translation.json";
import * as actionTypes from "../actions/actionTypes";
import { combineReducers } from "redux";

const getLanguageFromLocalStorage = () =>{
  var language = localStorage.getItem('language');
  if  (language === null || language === ""){
      language = "EN";
  }
  return language;
}
const saveLanguageToLocalStore = (language) => {
  localStorage.setItem('language', language);
}

const initialState = {
  language: getLanguageFromLocalStorage(),
  data: dataEN,
};

const setLanguage = (state, action) => {
  let data;
  switch (action.language) {
    case "EN":
      data = dataEN;
      break;
    case "FR":
      data = dataFR;
      break;
    case "RU":
      data = dataRU;
      break;
    default:
      break;
  }

  saveLanguageToLocalStore(action.language);
  return {
    ...state,
    ...{
      language: action.language,
      data,
    },
  };
};

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LANGUAGE:
      return setLanguage(state, action);
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  languageReducer,
});

export default rootReducer;
