import React from "react";
import { useParams } from "react-router-dom";
import { SecondaryButton } from "../shared/Button";
import { Link } from "react-router-dom";

const images = {
  salledebains: [
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/1.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/2.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/3.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/4.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/5.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/6.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/7.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/8.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/9.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/10.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/11.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/12.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/13.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/14.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/15.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/16.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/17.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/18.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/19.jpg",
  ],
  carin: [
    "https://citibatiment.s3.eu-west-3.amazonaws.com/carin/1.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/carin/2.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/carin/3.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/carin/4.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/carin/5.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/carin/6.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/carin/7.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/carin/8.jpg",

  ],
  jasmin: [
    "https://citibatiment.s3.eu-west-3.amazonaws.com/jasmin/1.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/jasmin/2.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/jasmin/3.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/jasmin/5.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/jasmin/4.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/jasmin/6.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/jasmin/7.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/jasmin/8.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/jasmin/9.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/jasmin/10.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/jasmin/11.jpg",
  ],
  ahma: [
    "https://citibatiment.s3.eu-west-3.amazonaws.com/ahma/2.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/ahma/3.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/ahma/4.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/ahma/5.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/ahma/1.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/ahma/7.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/ahma/6.jpg",
  ],
  spens: [
    "https://citibatiment.s3.eu-west-3.amazonaws.com/Spens/1.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/Spens/3.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/Spens/4.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/Spens/6.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/Spens/7.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/Spens/8.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/Spens/9.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/Spens/10.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/Spens/11.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/Spens/16.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/Spens/17.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/Spens/18.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/Spens/19.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/Spens/21.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/Spens/22.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/Spens/23.jpg",
  ],
  card9: [
    "https://citibatiment.s3.eu-west-3.amazonaws.com/varin/1.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/varin/2.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/varin/3.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/varin/5.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/varin/4.jpg",

  ],
  card8: [
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/1.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/2.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/3.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/5.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/salle-de-bain/4.JPG",

  ],
  mani: [
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/13.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/1.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/2.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/3.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/3.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/4.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/4.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/5.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/5.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/6.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/6.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/7.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/8.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/9.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/7.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/8.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/9.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/10.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/10.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/11.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/11.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/12.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/mani/14.jpg",
  ],
  card6: [
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/1.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/2.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/3.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/4.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/5.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/6.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/7.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/8.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/9.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/10.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/11.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/12.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/13.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/14.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/15.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/16.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/17.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/18.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/cassin/19.jpg",
    

  ],
  card5: [
    "https://citibatiment.s3.eu-west-3.amazonaws.com/xihong/1.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/xihong/2.JPG",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/xihong/3.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/xihong/4.jpeg"
  ],
  card1: [
    "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/2.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/4.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/6.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/7.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/10.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/11.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/13.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/14.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/18.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/19.jpg",
    "https://citibatiment.s3.eu-west-3.amazonaws.com/av-roi-albert/20.jpg",
  ],
};

function House() {
  let { topicId } = useParams();

  let image = images[topicId].map((image) => {
    return (
      <div className="mt-5 col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center mb-3">
        <div className="" style={{maxHeight: "250px"}}>
          <img src={image} className="card-img-top rounded-0" alt="portofolio"  style={{maxHeight: "250px"}}/>
        </div>
      </div>
    );
  });

  return (
    <div className="container">
      <div className="mt-5 pt-5 d-flex align-self-end">
        <Link to="/portofolio">
          <SecondaryButton text='Back'/>
        </Link>
      </div>
      <div className="row">
        {image}
      </div>
    </div>
  );
}

export default House;