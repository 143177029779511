import React, { useEffect } from "react";
import "./testimonials.css";
import star from "./images/Star1.png";

function Testimonial(props) {
  const setObserver = () => {
    // Remove the transition class
    const square = document.querySelector(".star");
    const stars = document.getElementsByClassName("star");

    for (const star of stars) {
      star.classList.remove("star-animation");
      star.classList.remove("star-animation-1");
      star.classList.remove("star-animation-2");
      star.classList.remove("star-animation-3");
      star.classList.remove("star-animation-4");
    }

    if (square) {
      square.classList.remove("star-animation");
    }

    // Create the observer, same as before:
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const children = entry.target.children;

        if (entry.isIntersecting) {
          children[0].classList.add("star-animation");
          children[1].classList.add("star-animation-1");
          children[2].classList.add("star-animation-2");
          children[3].classList.add("star-animation-3");
          children[4].classList.add("star-animation-4");
          return;
        }

        children[0].classList.remove("star-animation");
        children[1].classList.remove("star-animation-1");
        children[2].classList.remove("star-animation-2");
        children[3].classList.remove("star-animation-3");
        children[4].classList.remove("star-animation-4");
      });
    });

    const allStars = document.querySelectorAll(".star-div");
    for (const node of allStars) {
      observer.observe(node);
    }
  };

  useEffect(() => {
    setObserver();
  }, []);

  return (
    <div className="col-sm-12 col-md-4 mt-3 card-div pt-5 pb-5">
      <div className="testimonial-box">
        <div>
          <div className="star-div">
            <img src={star} alt="star" className="star star-animation" />
            <img src={star} alt="star" className="star star-animation-1" />
            <img src={star} alt="star" className="star star-animation-2" />
            <img src={star} alt="star" className="star star-animation-3" />
            <img src={star} alt="star" className="star star-animation-4" />
          </div>
          <div className="testimonial-text">{props.data.text}</div>
        </div>
        <div className="testimonial-footer">{props.data.name}</div>
      </div>
    </div>
  );
}

function Testimonials(props) {
  const testimonials = [
    {
      id: 0,
      name: "Isabelle Desmarets",
      text: "Réactif ;professionnels sont les deux mots à retenir. Un chantier propre , efficace et organisé. Cela fait vraiment plaisir. Merci encore pour votre travail.",
      date: "02/2020",
    },
    {
      id: 1,
      name: "Ofi Skenazene",
      text: "Très satisfait de leur travail, ils sont très ponctuels, très gentils, un travail très propre. Nous recommandons fortement cette société ! Merci encore pour votre gentillesse et votre travail de qualité.",
      date: "08/2023",
    },
    {
      id: 2,
      name: "Marina Guzzo",
      text: "Super travail, très professionnel, propre, rapide et à l’écoute! Je recommande a 100% pour tous types de travaux",
      date: "07/2021",
    },
  ];

  const testimonialCards = testimonials.map((testimonial) => (
    <Testimonial data={testimonial} />
  ));

  return (
    <div style={{ backgroundColor: "#EEE" }} className="pb-5">
      <div className="d-flex align-items-center justify-content-center">
        <div className="container mt-5">
          <div className="row">{testimonialCards}</div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
