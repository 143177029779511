import * as apiConfig from "../helpers/apiconfig"


export function sendMessage(message){

    const options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(message)
    }

    return fetch(`${apiConfig.getApiUrl()}/email/sendEmail`, options);
}

export function subscribe(message){

    const options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(message)
    }

    return fetch(`${apiConfig.getApiUrl()}/email/sendEmail`, options);
}
 