import React from "react";
import { Link } from "react-router-dom";
import "./blog.css";

export default function BlogCard(props) {
  return (
    <div className="col ml-5 blog-card">
      <div className="blog-card-body">
        <Link to="/blog/blog1">
          <div className="card-image">
            <img
              src={require("./assets/1.jpg")}
              alt="Bathroom"
              className="blog-card-image"
            />
          </div>
          <h5 className="card-title p-2 pt-3">
             {props.data.title}
          </h5>
          <hr style={{"width": "20%"}}/>
            <div className=" text-body-secondary fs-6">
            {props.data.date}
          </div>
        </Link>
      </div>
    </div>
  );
}
