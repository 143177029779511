import React, { Component } from "react";
import "./App.css";
import Menu from "./components/menu/menu.jsx";
import Home from "./components/home/home";
import ServicesDetails from "./components/services/servicesDetails";
import Footer from "./components/contact/footer";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import LanguageSelect from "./components/language/language";
import { connect } from "react-redux";
import { setLanguage } from "./store/actions/language";
import AboutDetails from "./components/about/aboutDetails";
import { Routes, Route , BrowserRouter} from "react-router-dom";
import PortofolioPage from "./components/galery/portofolioPage.jsx";
import House from "./components/galery/house";
import ContactPage from "./components/contact/contactPage";
import SmoothScroll from "./components/home/smooth-scroll.jsx";
import BlogPage from "./components/blog/blogPage";
import BlogItem from "./components/blog/blogItem";

library.add(fab, fas);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.state.data) {
      this.setState({ data: nextProps.data });
    }
  }

  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Menu data={this.state.data ? this.state.data["menu"]: {}} />
          <SmoothScroll>
          <Routes>
            <Route 
              exact 
              path="/" 
              element={
                <Home 
                  dataAbout={this.state.data ? this.state.data["aboutUs"]: {}}  
                  dataServices={this.state.data ? this.state.data["services"]: {}} 
                  dataHome={this.state.data ? this.state.data["home"]: {}}
                  dataReviews={this.state.data ? this.state.data["reviews"] : {}}
                  companyValues={this.state.data ? this.state.data["companyValues"] : {}}
                />
              }  
            />
            <Route 
              path="/about"  
              element={ <AboutDetails data={this.state.data ? this.state.data["aboutUs"]: {}}/>} 
            />
            <Route 
              path="/services" 
              element={<ServicesDetails data={this.state.data ? this.state.data["services"]: {}}/>} 
            />
            <Route 
              path="/blog" 
              element={<BlogPage data={this.state.data ? this.state.data["services"]: {}}/>} 
            />
            <Route 
              path="/blog/:blogId" 
              element={<BlogItem data={this.state.data ? this.state.data["services"]: {}}/>} 
            />
            <Route exact 
              path="/portofolio" 
              element={<PortofolioPage/>}
            />
            <Route 
              path={`/portofolio/:topicId`}
              element={<House id={this.state.topicId}/>}
            />
            <Route 
              path="/contact" 
              element={<ContactPage data={this.state.data ? this.state.data["contact"]: {}}/>}
            />
          </Routes>
          </SmoothScroll>
        </BrowserRouter>
        <Footer data={this.state.data ? this.state.data["contact"]: {}} />
        <LanguageSelect setLanguage={this.props.language} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.languageReducer.data,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    language: (lang) => dispatch(setLanguage(lang)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
