import React from "react";
import first from "./images/1.png";
import second from "./images/2.png";
import third from "./images/3.png";
import daikin from "./images/daikin.jpg";
import legrand from "./images/legrand.jpg";
import "./partners.css";

function Partners(props) {
  return (
    <div className="scroll">
      <div>
        <img src={first} alt="" className="grey-image" />
        <img src={second} alt="" className="grey-image" />
        <img src={third} alt="" className="grey-image" />
        <img src={legrand} alt="" className="grey-image" />
        <img src={daikin} alt="" className="grey-image" />
      </div>
      <div>
        <img src={first} alt="" className="grey-image" />
        <img src={second} alt="" className="grey-image" />
        <img src={third} alt="" className="grey-image" />
        <img src={legrand} alt="" className="grey-image" />
        <img src={daikin} alt="" className="grey-image" />
      </div>
    </div>
  );
}

export default Partners;
