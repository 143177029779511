import React from "react"
import BlogCard from "./blogCard";
import './blog.css';

const myBlogs = [
    {
        id: "1",
        title: "Comment créer un design d'intérieur personnalisé pour toute la famille",
        author: 'Tudor Cheibas',
        date: "Septembre 20, 2023",
    }
];

export default function BlogPage(props) {
    const blogItems = myBlogs.map(blog => <BlogCard data={blog} ></BlogCard>)
    return (
        <div className="blog-page pt-5 d-flex pb-5">
            <div className="container-md">
                <div className="  row justify-content-evenly mt-5">
                {blogItems}
                </div>
            </div>
           
        </div>
    );
}