import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import { createStore } from "redux";
import rootReducer from "./store/reducers/reducer";
import AWS from 'aws-sdk';
import * as dotenv from 'dotenv'
dotenv.config({ path: '../.env', override: true });
require("dotenv").config({path: '../.env'})
const store = createStore(rootReducer);
// Initialize the Amazon Cognito credentials provider
AWS.config.region = 'us-east-2'; // Region
AWS.config.accessKeyId = process.env.REACT_APP_API_AWS_ACCESS_KEY_ID;
AWS.config.secretAccessKey = process.env.REACT_APP_API_AWS_SECRET_ACCESS_KEY;


AWS.config.apiVersions = {
  s3: '2006-03-01',
};

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
<React.StrictMode>
  <Provider store={store}>
  <App />
  </Provider>
</React.StrictMode>
);

window.addEventListener('scroll', () => {
  const verticalScrollPx = window.scrollY || window.pageYOffset;
  const nav = document.getElementById("main-nav");

  if (verticalScrollPx < 200) {
    nav.classList.add("background-white");
  } 
  if (verticalScrollPx === 0 ) {
    nav.classList.remove("background-white");
  }
});